  /* Login page start*/
/* .login-container {
  position: relative;
} */

 /*  changes by bharshal */


 .headerLink{
  font-size: 0.75rem; 
  color: #810055;
  font-family:"G&B-BodyCopy",sans-serif;
}

.logoHeader{
  /*  changes by bharshal */
padding: 0px 6rem;
border: 1px solid #F1F1ED;
position: absolute;
z-index: 9999;
background-color: white;
height: 56px;
margin: 0px !important;
width: 100%;
}

.namelogo {
  /*  changes by bharshal */
  font-size: 3.75rem;
  color: #810055 !important;
  font-family: 'G&B-Headline', sans-serif;
}
.namelogosecond {
  /*  changes by bharshal */
 font-size: 1.25rem;
 color: #707070 !important;
 font-family: 'G&B-Headline', sans-serif;
}
  /*  changes by bharshal */
.sidebar-body.open {
  height: 85vh;
  overflow-y: auto;
  padding: 1px;
}
.head-toggle:hover{
  /*  changes by bharshal */
  fill: #810055;
}
.login-container {
  overflow: hidden;
}
.head-toggle{
  /* align-self: center; */
  font-size: 32px;
  fill: #707070 ;
}
 /* .head-toggle  {
  
    margin-left:70px !important;
  
} */
    
/* Login page start*/
/* .login-container {
  position: relative;
} */

.place-right {
  display: flex !important;
  align-items: center;
  justify-content: space-evenly;
}

.card{
  border-color: #F1F1ED !important;
}
/* change by tpratik */

.loginLeftImage img {
  margin-top: 3%;
  width: 200%;
  height: 105%;
  object-fit: cover;
  overflow: hidden;
  right: -8%;
  top: 2%;
  position: absolute;
}

.loginLeftImage {
  position: relative;
  height: calc(100vh - 0px);
  margin-left: 40%;
  /* padding: 5%; */
}

.loginErrorDiv {
  background: #FEF1F1;
  color: #e1142e 
  /* color change by tpratik */
  ;
  border-radius: 5px;
  text-align: left;
  padding: 0.8rem;
  font-size: 14px;
  .loginErrorDivHeader {
    font-weight: 600;
  }
}

.LoginRightInput {
  text-align: auto;
  .form-label {
    font-size: 14px;
    /* font-weight: 500; */
    margin-bottom: 0px;
  }
}
/* change by tpratik */
/* .forgotButton {
  width: 100%;
} */

.projectname{
  text-align: left;
  margin-left: 5rem;
  margin-bottom: 5rem;
}
.forgotButton {
  /* changes by bharshal */
  width: 70%;
  background-color: #810055 !important;
  border: #810055 !important;
  border-radius: 16px !important;
}

.login-image {
  height: 100vh;
  width: 100vw;
}

.logo {
  margin-top: 20px;
}

.login-box {
  width: 353px;
  border-radius: 16px;
  position: absolute;
  top: 25%;
  right: 5%;
  height: 340px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Login-btn-container {
  width: 95%;
}

/* .login-btn {
  width: inherit;
  border-radius: 0 !important;
  font-family: "WorkSans-SemiBold" !important;
  margin-bottom: 10%;
  background: #084ce8 0% 0% no-repeat padding-box !important;
} */

/* commited by tpratik */
.login-header {
  font-size: 20px;
  /* font-family: "WorkSans-SemiBold" !important; */
  font-family: "G&B-BodyCopyHighlight",sans-serif !important;
/* commited by tpratik */

  margin-top: -25px;
  margin-bottom: 10px;
}

.carousel-caption {
  text-align: left !important;
  left: 10% !important;
  top: 25% !important;
  right: unset !important;
  bottom: unset !important;
  font-size: 20px;
  /* font-family: "WorkSans-Medium"; */
  font-family: "G&B-Headline",sans-serif;

  width: 50%;
}

.caption-title {
  /* font-family: "WorkSans-SemiBold"; */
  font-family: "G&B-BodyCopyHighlight",sans-serif;
  font-size: 60px;
  width: fit-content;
  height: fit-content;
}

.caption-sub-title {
  /* font-family: "WorkSans-Medium"; */
  font-family: "G&B-Headline",sans-serif;
  font-size: 20px;
  width: fit-content;
  height: fit-content;
  margin-top: 10px;
}

/* from here  */

/* Login page end*/

/*Home */
/* .home {
  /* background: #eff3fc 0% 0% no-repeat padding-box; 
  background: #FFFFFF 0% 0% no-repeat padding-box;
  display: flex;
  min-height: 100vh !important;
}

.center-panel {
  width: 96%;
}

.home.open .center-panel {
  width: 79% !important;
}

/*Home end*/

/* Side bar start
.side-bar {
  width: 21% !important;
  /* width: fit-content !important; 
  transition: all 0.4s ease;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
   justify-content: space-evenly; 
  height: 100vh; 
}

.sidebar-head {
   float: right; 
  height: 40px;
  display: flex;
  justify-content: flex-end;
  box-shadow: 3px 3px 6px #f2f2f2;
  padding-right: 5px;
  position: fixed;
  width: inherit;
  background: inherit;
  z-index: 12;
} */

.home {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  display: flex;
  min-height: 100vh !important;
}

.center-panel {
  width: 96%;
}

.home.open .center-panel {
  width: 79% !important;
}

/*Home end*/

/* Side bar start*/
.side-bar {
  width: 21% !important;
  /* width: fit-content !important; */
  transition: all 0.4s ease;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  border-right: 2px solid #F1F1ED;
    /* justify-content: space-evenly; */
  /* height: 100vh; */
}

.sidebar-head {
  /* float: right; */
  height: 40px;
  display: flex;
  /* justify-content: flex-end; */
   /* changes by bharshal */
  /* box-shadow: 3px 3px 6px #f2f2f2; */
  /* padding-right: 5px; */
  position: fixed;
  width: inherit;
  background: inherit;
  z-index: 12;
   /* changes by bharshal */
  border: 1px solid #F1F1ED;
}
/* change by bharshal */

/* .head-toggle {
  align-self: center;
} */

.side-bar:has(.sidebar-body.closed) {
  width: 4% !important;
}

.home.closed .sidebar-head {
  justify-content: center !important;
}
.home.open .sidebar-head .head-toggle{
  margin-left: 70px !important;
}

.sidebar-head .user-section {
  display: none;
}

.sidebar-body {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-bottom: 50px;
  margin-top: 40px;
}


.nav-list {
  list-style-type: none;
}

.footer {
  display: flex;
  justify-content: center;
  background: white;
  height: 50px;
  position: fixed;
  width: 20%;
  bottom: 0;
  align-items: center;
}

.home.closed .footer {
  width: 2%;
}

.footer-logo {
  margin: 20px 0 !important;
  max-width: 70%;
  /* height: 10%; */
  /* position: fixed;
  bottom: 1%; */
}

.closed .footer-logo {
  margin: 20px 0 !important;
  max-width: 70%;
  position: relative;
  left: 7px;
  height: 80px;
  display: None;
}

/* CHANGED */

.form-check-input[type="checkbox"] {
  border: 1px solid #707070 !important;
}

/* CHNAGED END */
/* Side bar end*/

/* Header start*/


/* Header start*/
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* max-height: 20%; */
  /* height: fit-content; */
  height: 40px;
  padding: 1%;
  background: #ffffff 0% 0% no-repeat padding-box;
  position: fixed;
  width: inherit;
  z-index: 12;
}

.user-logo {
  /* background: #ebe4d5 0% 0% no-repeat padding-box; */
  border-radius: 50%;
  /* height: max-content;
  width: max-content; */
  height: 1rem;
  width: 1rem;
  padding: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  background: #F1F1ED;
  color: #810055;

}

.user-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}

.user-info {
  display: flex;
  flex-direction: column;
  margin: 0 0.5rem;
  justify-content: center;
  align-items: flex-start;
  color: #5f5f5f;
  padding: 0.25rem;
  font-size: 12px;
}

.user-name {
  font-family: "G&B-Headline",sans-serif;
  line-height: 1rem;
  color: #333333;
}

.user-email {
  line-height: 1rem;
  color: #707070;
}

.sidebar-head-header {
  display: none;
}

.app-name {
  display: flex;
  align-items: center;
  font-family: "G&B-BodyCopyHighlight",sans-serif;
  color:#810055;
}

.profile {
  border-radius: 0 !important;
  padding: 10px;
  box-shadow: 0px 3px 12px #69696929;
  border-color: #f1f1ed !important;  
}

/* Header end *

/* Companyselection start */

/* Companyselection end */

/* .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* max-height: 20%;
  /* height: fit-content;
  height: 40px;
  padding: 1%;
  background: #ffffff 0% 0% no-repeat padding-box;
  position: fixed;
  width: inherit;
  z-index: 12;
}

/* Changed 



.user-action {
  cursor: pointer;
}

.headerMenu {
  position: absolute;
  height: 2.5rem;
  width: 100px;
  background-color: #ffffff;
  top: 2.5rem;
  right: 0;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  box-shadow: 3px 3px 6px #f2f2f2;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 1;
}

.headerMenu-item {
  display: flex;
  text-decoration: none;
  color: #5f5f5f;
  font-size: 12px;
  font-weight: 500;
  align-items: center;
}

.headerMenu.show {
  opacity: 1;
  visibility: visible;
}

/* Changed  END

.user-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 1%;
  align-items: center;
}

.user-logo {
  /* background: #ebe4d5 0% 0% no-repeat padding-box; 
  border-radius: 50%;
  /* height: max-content;
  width: max-content; 
  height: 1rem;
  width: 1rem;
  padding: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.user-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.user-info {
  display: flex;
  flex-direction: column;
  margin: 0 0.5rem;
  justify-content: center;
  align-items: flex-start;
  color: #5f5f5f;
  padding: 0.25rem;
  font-size: 12px;
}

.user-name {
  font-family: "WorkSans-Medium";
  line-height: 1rem;
  color: #000000;
}

.user-email {
  line-height: 1rem;
  color: #6f6f6f;
}

.sidebar-head-header {
  display: none;
}

.app-name {
  font-size: 18px;
  display: flex;
  align-items: center;
  font-family: "WorkSans-SemiBold";
}

.profile {
  border-radius: 0 !important;
  padding: 10px;
  box-shadow: 0px 3px 12px #69696929;
} */

/* Header end */

/* Main start*/

.main-body {
  padding: 0 15px;
  margin-top: 70px;
}

/* Main end */


/* RoleMaster Start */
.parentmenu-row {
  /* background-color: #f5f5ff; changed*/
  background-color: #FFFFFF;
  font-size: 14px;
  padding: 5px;
  margin-right: 0% !important;
  margin-left: 0% !important;
  align-content: center;
  text-align: center;
  color: #333333;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}

.submenu-row {
  font-size: 14px;
  padding: 5px;
  align-content: center;
  text-align: center;
  color: #0e1220;
}

.center-align {
  text-align: center;
}
 /* to here */
/* .tabledata-format {
  border: 1px solid #dfe5f8;
  padding: 0px 0px 10px 0px;
}  commited by tpratik */

.tabledata-format {
  border: 1px solid #F1F1ED;
  padding: 0px 0px 10px 0px;
}

.status-switch {
  margin-left: 2px;
  margin-top: 10px !important;
}

/* RoleMaster End */
/* Project Seam*/
.project-seam-title {
  /* font-family: "WorkSans-SemiBold" !important; */
  font-family: "G&B-BodyCopyHighlight",sans-serif !important;
  font-size: 16px !important;
  padding: 15px;
  /* margin: 15px 0;  */
}

/* Offer Create Start */
/* .detailsTile {
  font-weight: 600;
} */
/* commented by tpratik */
.projDetails {
  padding: 14px;
  background-color: #e2ebff;
}


.button-create,
.button-submit {
  border-radius: 0px !important;
  /* height: 40px; */
  width: 128px;
  margin-left: 16px;
  margin-top: 5px;
  padding: 10px !important;
}

.button-create.disabled {
  background-color: #dfe5f8 !important;
  border-color: #dfe5f8 !important;
  width: 128px;
}

/* c0c1c4 */

.button-container,
.button-col {
  display: flex;
  justify-content: end;
  width: 100%;
}

/* .back-button {
  border-radius: 0 !important;
  background-color: white !important;
  color: #0745d3 !important;
  margin-left: 16px;
  /* height: 41px; 
  margin-top: 5px;
  padding: 10px !important;
} */
 /* commented by tpratik */
.back-button {
  border-radius: 0 !important;
  background-color: #ffffff !important;
  color: #810055 !important;
  margin-left: 16px;
  /* height: 41px; */
  margin-top: 5px;
  padding: 10px !important;
}

.offer-msg {
  /* font-family: "WorkSans-SemiBold" !important; */
  font-family: "G&B-BodyCopyHighlight",sans-serif !important;
  font-size: 18px !important;
}

/* Offer Create End */

/*Common CSS Start*/
.section-title,
.section-title th {
  /* font-family: "WorkSans-Medium" !important; */
  font-family: "G&B-Headline",sans-serif !important;

}

.page-title {
  font-size: 20px !important;
}

.page-sub-title {
  color: #707070;
  padding-bottom: 4px;
  font-size: 12px !important;
}

.fieldLabel.Active {
  /* color: #1cba23; */
  color: #33C037;

}

.fieldLabel.Inactive {
  color: #a4a5aa;
}

/*Common CSS end*/

/* button start */
/* .btn-color {
  background-color: #084ce8 !important;
  border-radius: 0 !important;
} */
/* change by tpratik */
.btn-color {
  border-radius: 16px !important;
 border-color: #810055 !important;  
 color: #fff !important;
 background-color: #810055 !important;
}
/* 
.btn-color.disabled {
  background: #C0C1C4 0% 0% no-repeat padding-box !important;
  border-color: #C0C1C4 !important;
  color: white !important;
} */
/* change by tpratik*/
/* .reset-button {
  border-radius: 0 !important;
  border-color: #084ce8 !important;
  color: #084ce8 !important;
} */

.reset-button {
  border-radius: 16px !important;
  border-color:#F1F1ED  !important; 
  color: #707070 !important;
}
/* added  by tpratik */


/* .reset-button:hover {
  border-color: #084ce8 !important;
  background-color: #084ce8 !important;
  color: white !important;
}
 commited by tpratik */
 .reset-button:hover {
  border-color: #810055 !important;
  background-color: #fff !important;
  color: #810055 !important;
}

 .action-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.right-alignement {
  float: right;
}

.btn-position {
  text-align: right;
}

.semiBoldToggleButton {
  /* font-family: "WorkSans-SemiBold" !important; */
  font-family: "G&B-BodyCopyHighlight",sans-serif !important;

}

/* button end */

/* Modal start */

.modal-title {
  font-family: "G&B-Headline",sans-serif;
  font-size: 16px !important;
  color: #333333;
  
}

.modal {
  background: rgba(59, 63, 74, 0.85);
}


.modal-content {
  border-radius: 20 !important;
}

.modal-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  color: #333333;
}

/* Modal end*/

/* Toast Container start */
.toast-container {
  width: -webkit-fill-available !important;
  padding: 0 !important;
  margin: 0 !important;
  top: 40px !important;
  right: 0 !important;
  left: 3%;
  z-index: 13 !important;
}

.home.closed .toast-container {
  left: 3%;
}

.home.open .toast-container {
  left: 21% !important;
}

.Toastify__toast {
  border-radius: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-height: 1% !important;
  /* background-color: #33c037 !important; */
  /* height: 40px !important; */
}

.toast-warn {
  background-color: #707070 !important; 
}
.toast-err {
  background-color: #e1142e !important;
}
.toast-success {
  background-color: #33C037 !important;
}
.toast-info {
  background-color: #810055 !important; 
}
.toast-dark {
  background-color: #333333 !important;
}

.Toastify__toast-body>div:last-child {
  /* font-family: "WorkSans-Regular"; */
  font-family:"G&B-BodyCopy",sans-serif;
  font-size: 12px;
}

/* Toast Container End */

/* for Input Placeholder start*/

.form-select:focus,
.form-control:focus {
  border-color: #ddd !important;
  box-shadow: none !important;
}

.form-check-input[type="radio"] {
  border: 1px solid !important;
}

.form-check-input:focus,
.select__control--is-focused.css-t-7ipsp-control {
  box-shadow: none !important;
}

.lblstatus {
  transform: translateY(-50%);
  margin-top: 5px !important;
}

.sw-status {
  vertical-align: text-top;
  transform: translateY(-50%);
}

.text-field-with-error,
.text-field-with-error:focus,
.text-field-with-error .select__control.css-13cymwt-control,
.text-field-with-error .select__control--is-focused {
  border-color: #e1142e !important;
}

.text-label-with-error {
  border-color: #e1142e !important;
  color: #e1142e !important;
}

.optional {
  font-size: 14px !important;
  /* font-family: "WorkSans-MediumItalic" !important; */
  /* change by tpratik */
  font-family: "G&B-Headline",sans-serif !important;

}

.form-label {
  font-size: 14px;
}

.form-select {
  height: 39px !important;
}


.gray-shade {
  color: #6f6f70;
}

/* .fieldLabel {
  color: #6F6F6F;
  font-family: "WorkSans-semibold" !important;
  font-size: 14px;
} */
.fieldLabel {
  color: #707070;
  font-family: "G&B-BodyCopyHighlight",sans-serif !important;
  font-size: 14px;
}

.column-heading {
  left: 20px;
  position: relative;
  text-align: center;
  height: 100px;
}

.menu-header {
  display: flex;
}

/* .text-size {
  font-size: 14px;
  padding: 5px;
  align-content: center;
  text-align: center;
  color: #6f6f6f;
} */
.text-size {
  font-size: 14px;
  padding: 5px;
  align-content: center;
  text-align: center;
  color: #707070;
}
/* change by tpratik */


.menuhead-col {
  text-align: left;
}

.submenuhead-col {
  margin-left: 3%;
}

.session-col {
  margin-left: 30px;
}

.select-all-row {
  /* border-bottom: 1px solid #dfe5f8; */
  border-bottom: 1px solid #f1f1ed; 
  /* change by tpratik */
  background-color: #ffffff;
  font-size: 14px;
  padding: 5px;
  margin-right: 0% !important;
  margin-left: 0% !important;
  /* border-left: 1px solid #C0C1C4;
  border-right: 1px solid #C0C1C4; */
  align-content: center;
  text-align: center;
  color: #333333;
}

/* .select-all-row *,
.checkbox-check.parent * {
  font-family: "WorkSans-SemiBold" !important;
  color: #000000 !important;
} */
/* commited by tpratik] */
.select-all-row *,
.checkbox-check.parent * {
  font-family: "G&B-Headline",sans-serif !important;
  color: #333333 !important;
}

.checkbox-check .form-check-label {
  /* color: #000000; */
  color: #707070;

}

.checkbox-check:has(.form-check-input:checked) .form-check-label {
  color: #333333 !important;
}

.downarrow-img {
  float: right;
  padding-right: 4%;
}

/* .form-check-input[type="checkbox"]:checked {
  background-image: url("./assets/checkboxWithTick.svg") !important;
  background-size: auto;
} */
 /* commited by tpratik */
.form-check-input[type="checkbox"]:checked {
  background-color:#810055 ;
  background-size: auto;
}
/* 
.form-check-input[type="radio"]:checked {
  background-color: #0636a5 !important;
  border-color: #0636a5 !important;
  accent-color: #0636a5 !important;
  background-image: none !important;
  appearance: auto !important;
} */

/* commited by tpratik */
.form-check-input[type="radio"]:checked {
  background-color: #810055 !important;
  border-color: #810055 !important;
  accent-color: #810055 !important;
  background-image: none !important;
  appearance: auto !important;
}

.form-control:disabled {
  background-color: #ffffff !important;
  color: #94969c;
}

.form-select:disabled {
  background-color: #ffffff !important;
  color: #707070;
}

.form-check:checked {
  background-color: #16911b;
  border-color: #16911b;
}

/* Form field end*/

/* Spinner*/

.newloader {
  /* changes by bharshal */
  position: absolute;
  /* width: 5rem !important;
  height: 5rem !important; */
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 50%;
  z-index: 1000;
}

.loader-box {
  /* changes by bharshal */
  display: grid;
  position: fixed;
  z-index: 1060;
  inset: 0;
  background: rgba(59, 63, 74, 0.85);
}

/* Spinner end*/

/* swal */

.swal2-container {
  background: rgba(59, 63, 74, 0.85) !important;
}

.swal2-title {
  font-family: "G&B-BodyCopyHighlight",sans-serif !important;
  font-size: 16px;
  padding: 0 !important;
  margin: 0 !important;
  /* display: flex !important;
  justify-content: flex-start; */
}

.swal2-html-container {
  /* font-family: "WorkSans-SemiBold" !important; */
  font-size: 14px !important;
  padding: 0 !important;
  margin: 0 !important;
}

/* .swal2-styled.swal2-confirm {
  background: #084ce8 0% 0% no-repeat padding-box;
  border-radius: 0;
  font-size: 14px !important;
  width: 40%;
  height: 48px;
}

.swal2-styled.swal2-cancel {
  /* padding-left: 40px !important;
  padding-right: 40px !important; 
  background-color: #fff !important;
  color: #084ce8 !important;
  border: 1px solid #084ce8 !important;
  /* font-family: "WorkSans-SemiBold" !important; 
  border-radius: 0;
  font-size: 14px !important;
  width: 40%;
  height: 48px;
}

.swal2-styled.swal2-cancel:hover {
  background-color: #084ce8 !important;
  color: #fff !important;
}

.swal2-styled.swal2-confirm:hover {
  background-color: #084ce8 !important;
  border: 1px solid #084ce8 !important;
}
 */
 
.swal2-styled.swal2-confirm {
  background: #810055 0% 0% no-repeat padding-box;
  border-radius: 24px;
  font-size: 14px !important;
  width: 40%;
  height: 48px;
}

.swal2-styled.swal2-cancel {
  /* padding-left: 40px !important;
  padding-right: 40px !important; */
  background-color: #ffffff !important;
  color: #707070 !important;
  border: 1px solid ;
  /* font-family: "WorkSans-SemiBold" !important; */
  border-radius: 24px !important;
  border-color: #F1F1ED !important;
  font-size: 14px !important;
  width: 40%;
  height: 48px;
}


.swal2-styled.swal2-cancel:hover {
  background-color: #810055 !important;
  color: #ffffff !important;
}

.swal2-styled.swal2-confirm:hover {
  background-color: #810055 !important;
  border: 1px solid #810055 !important;
}

.swal-confirmation {
  width: 400px;
  height: 232px;
  padding: 0 !important;
  border-radius: 5px !important;
  overflow: auto;
}

.swal-confirmation-Information {
  width: 800px;
  height: 400px;
  padding: 0 !important;
  border-radius: 5px !important;
  overflow: auto;
}

.swal-confirmation-preview-offer {
  width: 400px;
  height: 250px;
  padding: 0 !important;
  border-radius: 5px !important;
}

.swal-preview-offer {
  margin: 15px !important;
}

.swal2-actions {
  /* justify-content: space-evenly; */
  /* align-items: end !important; */
  margin: 0 !important;
  /* margin-left: 20% !important; */
  /* margin-right: 20% !important; */
}

.swal2-close {
  content: url("./assets/x.svg") !important;
  margin-right: 1%;
  margin-top: 1%;
  width: auto;
  height: auto;
}

/* Swal end*/

.no-data {
  /* background-color: #eff3fc; */
  background-color: #ffffff;
  text-align: center;
  height: 62vh;
  padding: 5%;
}

.dashboard .no-data {
  background-color: unset;
}

.no-data-msg {
  color: #a4a5aa;
  /* font-family: "WorkSans-Medium"; */
  font-family: "G&B-Headline",sans-serif;
  font-size: 16px;
}

/* CSS for page break in pdf report start */
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
  }
}

/* CSS for page break in pdf report end */

.main-div {
  width: 100%;
  background-color: #eff3fc;
}

/* ========================================== Tab View ======================================================= */

.btn-dashboard-showAll {
  float: right;
  margin-right: 16px;
}

.dashboard-subtitle-header {
  /* font-family: "WorkSans-SemiBold" !important; */
  font-family: "G&B-BodyCopyHighlight",sans-serif !important;
  font-size: 12px !important;
  padding: 0 2px;
  /* margin-right: 10px; */
  margin-top: 5px;
}

.dashboard-subtitle-value {
  /* font-family: "WorkSans-SemiBold" !important; */
  font-family: "G&B-BodyCopyHighlight",sans-serif !important;
  font-size: 20px !important;
}

.dashboard-subtitle-value-percent {
  /* font-family: "WorkSans-Medium" !important; */
  font-family: "G&B-Headline",sans-serif !important;
  font-size: 12px !important;
  color: #A4A5AA;
}

.dashboard-title-UserName {
  /* font-family: "WorkSans-Regular" !important; */
  font-family:"G&B-BodyCopy",sans-serif !important;
  font-size: 28px !important;
}

.dashboard-button-container {
  display: flex;
  align-items: center;
}

.dashboard-btn {
  margin-right: 10px;
}



.stat {
  /* text-align: center; */
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 0 !important;
}

/* =====================================signature-img======================================================================= */

/* Tab CSS End */

/* Mobile View Start */
@media only screen and (max-width: 767px) {

  /* g-input start */
  .g-input label {
    max-width: max-content !important;
  }

  /* g-input ends */
  /* tab css start */
  .signature-img {
    width: 100%;
    height: 90%;
  }

.home.open .head-toggle{
  margin-left: 70px !important;
}

  .login-box {
    top: 50% !important;
    left: 5% !important;
    width: 90% !important;
    height: 232px !important;
  }

  .caption-sub-title {
    font-size: 15px !important;
  }

  .caption-title {
    font-size: 30px !important;
  }

  /* login end*/

  /*sidebar*/
  .side-bar {
    height: inherit !important;
  }

  .side-bar,
  .sidebar-head .head-toggle {
    display: none;
  }

  

  .sidebar-head .user-section {
    display: flex;
    padding: 10px;
  }

  .sidebar-head {
    justify-content: flex-start;
    height: 48px;
  }

  .home.open .side-bar {
    width: 80% !important;
    display: block;
    top: 0;
    position: fixed !important;
    z-index: 15;
  }

  .sidebar-head-header {
    display: flex;
    flex-direction: column;
    padding: 16px;
  }

  /*sidebar end*/

  .page-title {
    display: flex;
    align-self: center;
    justify-content: center;
    text-align: center;
    font-size: 14px !important;
  }

  .page-sub-title {
    display: flex;
    align-self: center;
    justify-content: center;
    padding-bottom: 15px;
    font-size: 12px !important;
    text-align: center;
  }

  /*header start*/
  .header {
    /* height: fit-content !important; */
    height: 48px;
    align-items: center;
    width: 100vw;
  }

  .header .user-section {
    visibility: hidden;
  }

  /*header end */

  /* Offer Create Start */


  /* toast start*/
  .toast-container {
    left: 0 !important;
  }

  /*toast end*/
  /* Main Body*/
  .center-panel {
    width: 100%;
  }

  .main-body {
    /* padding: 1% 2% 0 2%; */
    /* padding-left: 16px !important;
    padding-right: 16px !important; */
    margin-top: 80px !important;
    margin-bottom: 30px;
  }

  .no-data {
    height: 63vh;
  }


  .spinner {
    position: absolute;
    width: 5rem !important;
    height: 5rem !important;
    top: 50%;
    bottom: 50%;
    left: 40%;
    right: 60%;
    z-index: 1000;
  }

  .footer {
    display: flex;
    justify-content: center;
    background: white;
    height: 50px;
    position: fixed;
    width: 20%;
    bottom: 0;
    left: 100px;
    align-items: center;
  }

  .dashboard-title-UserName {
    font-size: 22px !important;
  }


  /* =========================================================================================================== */

}

/* Mobile View End */


/* Mobile View End */
.custome-dropdown option {
   color: #707070  !important;
   background-color: #FFFFFF;
 /* change by tpratik */
}

.custome-dropdown option:hover {
  background-color: #F9F2F6 !important; /* Change  by tpratik*/
  color: #810055 !important;
}

/* width */
::-webkit-scrollbar,
.ag-body-horizontal-scroll-viewport,
.ag-horizontal-right-spacer {
  width: 10px;
  height: 10px;
}
 
/* Track */
::-webkit-scrollbar-track {
  background-color: white;
  /* -webkit-box-shadow: inset 0 0 6px #333(0,0,0,0.3) !important;  */
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 5px;
  /* box-shadow: inset 0 0 5px grey;
  border-radius: 5px; */
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  /* border-radius: 10px; */
  background: #707070;
  /* border-radius: 5px; */
}
 