

.sidebar-body .nav-list-item {
    /* changes by bharshal */
      color: #707070 !important;
      text-decoration: none;
      margin: 5px 0;
      margin-left: 15px;
  }
  
  .sidebar-body .nav-list-item.parent {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
  }
  
  .home.closed .sidebar-body .nav-list-item {
      justify-content: center;
      margin-left: 0;
  }
  
  .sidebar-body .nav-item:hover{
      /* changes by bharshal */
      color: #810055;
      background-color: #F9F2F6;
  }
  .sidebar-body .parent .menu-name {
      font-family: "G&B-BodyCopyHighlight",sans-serif;
  }
  
  .sidebar-body .menu {
      margin-left: 50px;
      font-size: 12px;
  }
  
  .sidebar-body .nav-sub-menu {
      list-style-type: none;
      padding-left: 3rem;
  }
  
  .sidebar-body.closed .menu-arrow,
  .sidebar-body.closed .menu-name {
      display: none
  }
  
  
  .sidebar-body .menu-arrow:hover svg path,
      .nav-item:hover > .menu-arrow svg path{
      /* changes by harshal */
      stroke:#810055 ;
  }
  .sidebar-body .menu-arrow {
      float: right;
      cursor: pointer;
      margin-right: 20px;
  }
  
  .sidebar-body .nav-item {
      margin: 18px 0;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* changes by bharshal */
      border: #707070;
  }
  
  
  .home.closed .sidebar-body .nav-item {
      justify-content: center;
      /* margin: 20px 0; */
  }
  
  .sidebar-body .sidebar-icon {
      display: inline-block;
      padding: 0;
  }
  
  .sidebar-body .nav-item.active .nav-list-item .menu-name {
      /* changes by bharshal */
      color: #810055;
      font-family: "G&B-BodyCopyHighlight",sans-serif;
  }
  
  .sidebar-body .nav-item.active .nav-list-item svg g rect
  
      /* {
      stroke: #fff !important;
      fill: none !important;
  } */
  
  .sidebar-body .nav-item.active .nav-list-item svg g {
      stroke-width : 1.1
  }
  
  
  .sidebar-body .nav-item.active {
      /* changes by bharshal */
      background:#F9F2F6 0% 0% no-repeat padding-box;
      color: #810055;
  }
  
  .sidebar-body .nav-item:hover .menu-name {
      color: #810055;
      font-family: "G&B-BodyCopyHighlight",sans-serif;
      /* changes by bharshal */
  }
   
  .sidebar-body .nav-item:hover .nav-list-item svg g path
   
      {
          /* changes by bharshal */
      stroke: #810055 !important;
      fill: none !important;
  }
  .sidebar-body .nav-item:hover .nav-list-item svg g circle
  {
      /* changes by bharshal */
  stroke: #810055 !important;
  fill: none !important;
  }
   
  .sidebar-body .nav-item:hover .nav-list-item svg g rect
  {
      /* changes by bharshal */
  stroke: #810055 !important;
  fill: none !important;
  }
   
  .sidebar-body .nav-item:hover .menu-arrow svg rect path
  {
      /* changes by bharshal */
  stroke: #810055 !important;
  fill: none !important;
  }
  .sidebar-body .nav-item:hover .nav-list-item svg g line
  {
      /* changes by bharshal */
  stroke: #810055 !important;
  fill: none !important;
  }

  .sidebar-body .nav-item.active .nav-list-item svg g rect 
  {
  stroke: #810055 !important;
  fill: none !important;
}
   
  .first{
      /* changes by bharshal */
      color: #810055;
      align-content: center;
      margin-top: 8px;
      margin-bottom: 0rem !important;
      font-family: 'G&B-Headline', sans-serif !important;
  }
   